import { Box } from "@mui/material";
import { Post } from "pages/post";
import { Profile } from "pages/profile";
import { DownloadCTA } from "features/download-cta";
import { TopBar } from "pages/preview/top-bar";
import { useState } from "react";
import { useParams } from "react-router-dom";

interface Props {}

export type PreviewParams = {
  usernameParam?: string;
  postParam?: string;
};

export const Preview: React.FC<Props> = (props) => {
  const [params, setParams] = useState<PreviewParams>({
    usernameParam: useParams().id,
    postParam: useParams().cid,
  });

  return (
    <div className="preview">
      <Box sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <TopBar />
        <Box
          sx={{
            display: "flex",
            marginTop: "50px", // height of top bar
            marginBottom: "200px", // height of bottom bar
            justifyContent: "center",
          }}
        >
          {params.postParam ? (
            <Post campaignID={params.postParam} onBackClick={setParams} />
          ) : (
            <Profile username={params.usernameParam} onCardClick={setParams} />
          )}
        </Box>
        <DownloadCTA />
      </Box>
    </div>
  );
};
