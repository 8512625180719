import PushieLogo from "./PushieLogo.svg";

interface Props {
  height: number;
  width: number;
}

export const Logo: React.FC<Props> = (props) => {
  return (
    <img
      src={PushieLogo}
      style={{ height: props.height, width: props.width }}
      alt="pushie logo"
    />
  );
};
