import {
  Box,
  Container,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useContext, useState } from "react";
import { logoutUser } from "pages/login/utils/cognitoAuth";
import { useNavigate } from "react-router-dom";
import { userContext } from "features/user-context/component";
import { Colors, TitleStyles } from "common/themes";
import { Logo } from "common/logo";

interface Props {}

export const TopBar: React.FC<Props> = (props) => {
  const { authToken } = useContext(userContext);
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchor(null);
  };
  return (
    <div className="topbar">
      <Box
        sx={{
          position: "fixed",
          left: "0",
          top: "0",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#ffffff",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderColor: Colors.Grey,
          width: "100vw",
          height: "50px",
          zIndex: 10,
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link
            onClick={() => navigate("/")}
            underline="none"
            sx={{
              ":hover": {
                cursor: "pointer",
              },
              display: "flex",
              alignItems: "center",
              color: "transparent",
            }}
          >
            <Logo height={40} width={40} />
            <Typography sx={TitleStyles}>pushie</Typography>
          </Link>
          {authToken && (
            <div className="acct-menu" onClick={handleClick}>
              <Box
                sx={{
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <PersonIcon />
              </Box>
            </div>
          )}
        </Container>
      </Box>
      {authToken && (
        <Menu open={open} anchorEl={menuAnchor} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              logoutUser();
              navigate("/login");
            }}
            sx={{ px: 2 }}
          >
            Log out
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};
