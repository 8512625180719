export const AWSCONFIG = {
  cognito: {
    userPoolId: "us-west-2_REVlhiQFe",
    userPoolClientId: "297hroa44bsrrn1l3bumgaidi1",
    region: "us-west-2",
  },
  api: {
    invokeUrl: "", // e.g. https://rc7nyt4tql.execute-api.us-west-2.amazonaws.com/prod',
  },
};
