import { CampaignData } from "features/campaign-context/component";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { CAMPAIGN_API } from "features/utils/constants";
import { CampaignCard } from "features/campaign-list/components/campaign-card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PreviewParams } from "pages/preview";
import { Colors, Fonts } from "common/themes";
import ShareIcon from "@mui/icons-material/Share";
import { ROUTES } from "App";

interface Props {
  campaignID?: string;
  onBackClick: (params: PreviewParams) => void;
}

export const Post: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const campaignID = props.campaignID;

  const [campaignData, setCampaignData] = useState<CampaignData>();
  const [loading, setLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(
      `${window.location.origin}${ROUTES.Post}/${props.campaignID}`
    );
  };

  useEffect(() => {
    const getCampaignByCampaignID = async (userID: string) => {
      try {
        var response = await axios.get(`${CAMPAIGN_API}/${campaignID}`, {});
        if (response.data.length > 0) {
          setCampaignData(response.data[0]);
          setLoading(false);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (!campaignID) {
      navigate("/");
    } else {
      getCampaignByCampaignID(campaignID);
    }
  }, [campaignID, navigate]);

  // Loading state
  if (loading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        maxWidth: "600px",
        px: 2,
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          justifyContent: "space-between",
          marginY: 2,
          display: "flex",
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          sx={{ textTransform: "none", color: Colors.PrimaryPurple }}
          onClick={() => {
            navigate(`/profile/${campaignData?.UserID}`);
            props.onBackClick({
              usernameParam: campaignData?.UserID,
            });
          }}
        >
          <Typography sx={{ fontFamily: Fonts.Default }}>
            {`@${campaignData?.UserID}`}
          </Typography>
        </Button>
        <Button
          variant="text"
          startIcon={<ShareIcon />}
          sx={{ textTransform: "none", color: Colors.PrimaryPurple }}
          onClick={onCopy}
        >
          <Typography sx={{ fontFamily: Fonts.Default }}>
            {isCopied ? "Copied" : "Copy Link"}
          </Typography>
        </Button>
      </Box>
      {campaignData && <CampaignCard campaign={campaignData} isPostPage />}
    </Box>
  );
};
