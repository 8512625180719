import CampaignList from "features/campaign-list/component";
import { CampaignData } from "features/campaign-context/component";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import { CAMPAIGN_API, PROFILE_API } from "features/utils/constants";
import { UserHeader } from "./components/user-header";
import { PreviewParams } from "pages/preview";
import { Box } from "@mui/material";

interface Props {
  username?: string;
  onCardClick: (params: PreviewParams) => void;
}

export const Profile: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const routerID = useParams().id;

  const usernameParam = props.username ?? routerID;

  const [userData, setUserData] = useState({
    displayName: "",
    profileDescription: "",
    profileImage: "",
  });
  const [campaignData, setCampaignData] = useState([] as CampaignData[]);
  const [loadingCampaignData, setLoadingCampaignData] = useState(true);

  useEffect(() => {
    const getUserProfile = async () => {
      const config = {
        params: {
          userID: usernameParam,
        },
      };
      try {
        var response = await axios.get(PROFILE_API, config);
        if (response.data.Items.length !== 0) {
          const profile = response.data.Items[0];
          setUserData({
            displayName: profile.DisplayName,
            profileDescription: profile.ProfileDescription,
            profileImage: profile.ProfileImage,
          });
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
        // navigate("/");
      }
    };

    const getCampaignData = async (userID: string) => {
      const config = {
        params: {
          userID: userID,
        },
      };
      try {
        var response = await axios.get(CAMPAIGN_API, config);
        setCampaignData(response.data.Items);
        setLoadingCampaignData(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (!usernameParam) {
      // navigate("/");
    } else {
      getUserProfile();
      getCampaignData(usernameParam);
    }
  }, [navigate, usernameParam]);

  // Loading state
  if (loadingCampaignData || !usernameParam || !userData.displayName) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <UserHeader
        username={usernameParam}
        displayName={userData.displayName}
        profileDescription={userData.profileDescription}
        profileImage={userData.profileImage}
      />
      <CampaignList
        campaignData={campaignData}
        onCardClick={(campaignID: string) => {
          props.onCardClick({
            postParam: campaignID,
          });
        }}
      />
    </Box>
  );
};
