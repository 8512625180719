import React from "react";
import { Box, Container, useTheme } from "@mui/material";
import WhyCardStyles from "./WhyCard.module.css";

interface Props {}

export const WhyCard: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <div id="why-pushie">
      <Box
        sx={{
          paddingY: "5rem",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingX: "100px",
            [theme.breakpoints.down("md")]: {
              paddingX: "50px",
            },
          }}
          disableGutters={true}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "75px",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <div className={WhyCardStyles.Title}>
                Engage your audience directly, drive more traffic 🚀
              </div>
              <Box
                sx={{
                  marginTop: "1.5rem",
                  paddingX: "2rem",
                  [theme.breakpoints.down("md")]: {
                    paddingX: 0,
                  },
                }}
              >
                <div className={WhyCardStyles.Subtitle}>
                  Pushie empowers you with a direct and effective communication
                  channel with your audience. Take control over your content
                  distribution to better engage your audience and drive traffic
                  to your content.
                </div>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                marginRight: "1.5rem",
                [theme.breakpoints.down("md")]: {
                  marginRight: 0,
                },
              }}
            >
              {getValueProp(
                "Maximize Reach, Minimize Effort",
                "Define the perfect push notification that captures your audience's attention and choose where it leads them—a new YouTube video, your latest blog post, or any destination you desire. It's your content, your rules.",
                "megaphone"
              )}
              {getValueProp(
                "Unlock Insights, Optimize Results",
                "Pushie provides advanced tools to optimize your notifications. Dive deep into analytics, measure sends and opens, and refine your approach. With A/B testing and user segmentation, you can target messages for different segments of your audience, ensuring maximum engagement and impact.",
                "graph"
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              {getValueProp(
                "Trustworthy, Non-Intrusive, and Personalized",
                "We understand the concerns of content creators. That's why Pushie puts user experience at the forefront. Followers have full control over the notifications they receive, allowing them to customize their feed based on their preferences. It's a win-win—a direct line of communication without being intrusive.",
                "thumbsup"
              )}
              {getValueProp(
                "Remove the Algorithms in your Way",
                "It's your audience and we give you the control to directly reach them. Unlike other platforms, we optimize to give you control and the right tooling to make the best decisions for your business.",
                "users"
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

const getValueProp = (
  title: string,
  subtitle: string,
  svg: "megaphone" | "graph" | "thumbsup" | "users"
) => (
  <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "1.5rem" }}>
    <Box
      sx={{
        marginRight: "1rem",
        marginBottom: "1rem",
        minWidth: "40px",
        height: "40px",
        backgroundColor: "#5c36e7",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 2,
      }}
    >
      <Box sx={{ width: "20px", height: "20px" }}>{getSVG(svg)}</Box>
    </Box>
    <Box sx={{ textAlign: "left" }}>
      <div className={WhyCardStyles.ValuePropTitle}>{title}</div>
      <Box sx={{ marginTop: ".5rem" }}>
        <div className={WhyCardStyles.ValuePropSubtitle}>{subtitle}</div>
      </Box>
    </Box>
  </Box>
);

const getSVG = (type: string) => {
  switch (type) {
    case "megaphone":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#ffffff"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
          />
        </svg>
      );
    case "graph":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#ffffff"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
          />
        </svg>
      );
    case "thumbsup":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#ffffff"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
          />
        </svg>
      );
    case "users":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#ffffff"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
          />
        </svg>
      );
  }
};
