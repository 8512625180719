import { Container, Typography } from "@mui/material";
import { FontSizes } from "common/themes";

interface Props {
  campaignTitle: string;
  campaignBody: string;
  imageURL?: string;
}

export function CampaignCardBody(props: Props) {
  return (
    <div className="campaign-card-body">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "start",
          py: 2,
          px: 3,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: FontSizes.Default,
          }}
        >
          {props.campaignTitle}
        </Typography>
        <Typography
          sx={{ fontSize: FontSizes.Default, whiteSpace: "pre-wrap" }}
        >
          {props.campaignBody}
        </Typography>
      </Container>
    </div>
  );
}
