import React from "react";
import { Container, Box, useTheme } from "@mui/material";
import ContactStyles from "./Contact.module.css";
import { Logo } from "common/logo";

interface Props {}

export const Contact: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingBottom: "150px",
        marginTop: "50px",
        [theme.breakpoints.down("md")]: {
          paddingBottom: "200px",
          alignItems: "center",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Logo height={50} width={50} />
        <div className={ContactStyles.Logo}>pushie</div>
      </Box>
      <div className={ContactStyles.copyright}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            [theme.breakpoints.down("md")]: {
              alignItems: "center",
            },
          }}
        >
          <Box>
            👋 Questions? Contact us: &nbsp;
            <a
              className={ContactStyles.copyright}
              href="mailto:hello@pushieapp.com"
            >
              hello@pushieapp.com
            </a>
          </Box>
          <Box>
            <a
              className={ContactStyles.copyright}
              href="https://www.pushieapp.com/terms-of-service"
            >
              Terms of Service
            </a>
            &nbsp;|&nbsp;
            <a
              className={ContactStyles.copyright}
              href="https://www.pushieapp.com/privacy"
            >
              Privacy Policy
            </a>
            &nbsp;|&nbsp;
            <a
              className={ContactStyles.copyright}
              href="https://www.pushieapp.com/cookies"
            >
              Cookie Use
            </a>
            &nbsp;|&nbsp;
            <a
              className={ContactStyles.copyright}
              href="https://www.pushieapp.com/eula"
            >
              EULA
            </a>
          </Box>
          <Box>© 2023 Pushie</Box>
        </Box>
      </div>
    </Container>
  );
};
