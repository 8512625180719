import { Box, useTheme, Typography, Container } from "@mui/material";
import { Colors, Fonts, FontSizes } from "common/themes";

interface Props {}

const AppStoreUrls = {
  apple: "https://apps.apple.com/us/app/pushie-stay-connected/id1644403500",
  google: "https://play.google.com/store/apps/details?id=com.pushie",
};

export const DownloadCTA: React.FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <div className="downloadcta">
      <Box
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          display: "flex",
          justifyContent: "center",
          backgroundColor: Colors.PrimaryPurple,
          width: "100%",
          minHeight: "100px",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            paddingX: 2,
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              height: "100%",
              width: "50%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
                alignItems: "center",
                textAlign: "center",
              },
              marginY: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: Fonts.Title,
                fontWeight: 800,
                color: Colors.White,
              }}
            >
              Join our Pushie community today
            </Typography>
            <Typography
              sx={{ fontSize: FontSizes.Default, color: Colors.White }}
            >
              Download Pushie today and never miss an update from your favorite
              creators again!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "50%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
                marginBottom: 2,
                justifyContent: "center",
              },
            }}
          >
            <a href={AppStoreUrls.apple} target="_blank">
              <img
                src="/Download_on_the_App_Store_Badge.svg"
                alt="Download on the App Store"
                style={{
                  height: "40px",
                  margin: "5px",
                }}
              />
            </a>
            <a href={AppStoreUrls.google} target="_blank">
              <img
                src="/Google_Play_Store_badge.svg"
                alt="Get it on Google Play"
                style={{
                  height: "40px",
                  margin: "5px",
                }}
              />
            </a>
          </Box>
        </Container>
      </Box>
    </div>
  );
};
