const OLIVIAGU_BACKEND =
  "https://p8mymc1ab6.execute-api.us-west-2.amazonaws.com/Prod";
const PUSHIE_DEV =
  "https://35v0sdc3e8.execute-api.us-west-2.amazonaws.com/Prod";
const PUSHIE_PROD =
  "https://kl0wxxu7f9.execute-api.us-west-2.amazonaws.com/Prod";

const BACKEND_PROD = PUSHIE_PROD;

export const FOLLOWER_API = `${BACKEND_PROD}/follower`;

export interface FollowerAPIGetResponse {
  UserID: string;
  FollowerID: string;
  CreatedDate?: string;
}

export const USER_API = `${BACKEND_PROD}/user`;

export const CAMPAIGN_API = `${BACKEND_PROD}/campaign`;

export const PROFILE_API = `${USER_API}/profile`;

export const EMAIL_API = `${BACKEND_PROD}/email`;
