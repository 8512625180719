import React from "react";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import FeatureCardStyles from "./HowItWorks.module.css";
import CreateScreen from "../../../../common/assets/CreateScreenPurple.png";
import NotifScreen from "../../../../common/assets/NotificationScreenPurple.png";
import FeedScreen from "../../../../common/assets/FeedScreen2x.png";
import PurpleBackground from "../../../../common/assets/PurpleBackground.png";

interface Props {
  titleCopy: string;
  subtitleCopy: string;
  featureImage: "create" | "notification" | "feed";
}

export const HowItWorksCard: React.FC<Props> = (props) => {
  const theme = useTheme();

  const getText = () => {
    return (
      <Box
        sx={{
          textAlign: "left",
          marginTop: "1rem",
          paddingX: "1rem",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingX: "3rem",
          },
          boxSizing: "border-box",
        }}
      >
        <div className={FeatureCardStyles.Title}>{props.titleCopy}</div>
        <Box sx={{ marginTop: "1.5rem" }}>
          <div className={FeatureCardStyles.Subtitle}>{props.subtitleCopy}</div>
        </Box>
      </Box>
    );
  };

  const getImg = () => {
    switch (props.featureImage) {
      case "create": {
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={CreateScreen}
              style={{ maxHeight: "350px", width: "auto" }}
              alt="creating a pushie"
            />
          </Box>
        );
      }
      case "notification": {
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={NotifScreen}
              style={{ maxHeight: "350px", width: "auto" }}
              alt="receiving a pushie notification"
            />
          </Box>
        );
      }
      default: {
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={FeedScreen}
              style={{ maxHeight: "400px", width: "auto" }}
              alt="pushie feed"
            />
          </Box>
        );
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "33.33%",
        [theme.breakpoints.down("md")]: {
          width: "100%",
          marginBottom: "3rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "400px",
          marginBottom: "1rem",
        }}
      >
        {getImg()}
      </Box>
      {getText()}
    </Box>
  );
};

export const HowItWorks = () => {
  const theme = useTheme();

  const backgroundAdjust = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div id="how-it-works">
      <Box
        style={{
          backgroundImage: "url(" + PurpleBackground + ")",
          backgroundSize: backgroundAdjust ? "auto 100%" : "100% 100%",
          backgroundPositionX: "right",
          marginBottom: "2rem",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingY: "5rem",
            textAlign: "center",
          }}
        >
          <div className={FeatureCardStyles.SectionTitle}>
            Simple, Instant, and Convenient 🎉
          </div>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
              },
              marginTop: "2rem",
            }}
          >
            <HowItWorksCard
              titleCopy="Craft your push notification"
              subtitleCopy="Control every part of the push notification including where it opens when a user taps on it -- directly to your new YouTube video, recent blog post, or latest merch drop."
              featureImage="create"
            />
            <HowItWorksCard
              titleCopy="Deliver content instantly to your community"
              subtitleCopy="Preview and send your push notification directly to your audience. Gain valuable analytics to optimize your push notification strategy and maximize engagement."
              featureImage="notification"
            />
            <HowItWorksCard
              titleCopy="A feed for every follower"
              subtitleCopy="Your push notifications don't just disappear, they're accessible in your followers' feeds with easy access to your content."
              featureImage="feed"
            />
          </Box>
        </Container>
      </Box>
    </div>
  );
};
