import axios from "axios";
import {
  FollowerAPIGetResponse,
  FOLLOWER_API,
  PROFILE_API,
} from "features/utils/constants";
import { addUserData } from "pages/login/utils/cognitoAuth";
import { createContext, useEffect, useState } from "react";

export interface UserData {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  profileDescription?: string;
  profileImage?: string;
}

export interface UserContextValue {
  userData: UserData;
  authToken: string;
  setUserData: React.Dispatch<React.SetStateAction<UserData>>;
  setAuthToken: React.Dispatch<React.SetStateAction<string>>;

  loading: boolean;
  userFollows: FollowerAPIGetResponse[];
  followUser: (userToFollow: string) => void;
  unfollowUser: (userToUnfollow: string) => void;
}

const defaultUserData = {
  username: "",
  firstName: "",
  lastName: "",
  email: "",
};

export const userContext = createContext<UserContextValue>({
  userData: defaultUserData,
  authToken: "",
  setUserData: () => null,
  setAuthToken: () => null,

  loading: true,
  userFollows: [],
  followUser: () => null,
  unfollowUser: () => null,
});

export const UserContextManager: React.FC = (props) => {
  const [userData, setUserData] = useState<UserData>(defaultUserData);
  const [authToken, setAuthToken] = useState("");
  const [stale, setStale] = useState(true);

  const [userFollows, setUserFollows] = useState(
    [] as FollowerAPIGetResponse[]
  );
  const [loading, setLoading] = useState(true);

  const addUserContext = (userObject: {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    authToken: string;
  }) => {
    setUserData({
      username: userObject.username,
      firstName: userObject.firstName,
      lastName: userObject.lastName,
      email: userObject.email,
    });
    setAuthToken(userObject.authToken);
  };

  useEffect(() => {
    if (stale || !authToken) {
      addUserData(addUserContext);
      setStale(false);
    }
  }, [stale, authToken]);

  useEffect(() => {
    const getUserFollows = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + authToken,
        },
      };
      try {
        var response = await axios.get(
          `${FOLLOWER_API}/${userData.username}`,
          config
        );
        setUserFollows(response.data.Items || []);
      } catch (error) {
        console.log(error);
      }
    };
    const getUserProfile = async () => {
      const config = {
        params: {
          userID: userData.username,
        },
        headers: {
          Authorization: "Bearer " + authToken,
        },
      };
      try {
        var response = await axios.get(PROFILE_API, config);
        if (response.data.Items.length !== 0) {
          const profile = response.data.Items[0];
          setUserData({
            ...userData,
            profileDescription: profile.ProfileDescription,
            profileImage: profile.ProfileImage,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (authToken && userData.username) {
      getUserFollows();
      getUserProfile();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, userData.username]);

  const followUser = async (userToFollow: string) => {
    const config = {
      headers: {
        Authorization: "Bearer " + authToken,
      },
    };

    const requestParams = {
      userID: userToFollow,
      followerID: userData.username,
    };

    try {
      await axios.post(FOLLOWER_API, requestParams, config);
      setUserFollows([
        ...userFollows,
        { UserID: userToFollow, FollowerID: userData.username },
      ]);
    } catch (error) {
      console.log(error);
    }
  };
  const unfollowUser = (userToUnfollow: string) => {
    const unfollowParams = {
      data: {
        userID: userToUnfollow,
        followerID: userData.username,
      },
      headers: {
        Authorization: "Bearer " + authToken,
      },
    };
    try {
      axios.delete(FOLLOWER_API, unfollowParams);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <userContext.Provider
      value={{
        userData,
        authToken,
        setUserData,
        setAuthToken,

        loading,
        userFollows,
        followUser,
        unfollowUser,
      }}
    >
      {props.children}
    </userContext.Provider>
  );
};
