import { useContext, useState } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { userContext } from "features/user-context/component";
import "./index.css";
import { FOLLOWER_API } from "features/utils/constants";
import axios from "axios";
import { Link } from "react-router-dom";
import { ROUTES } from "App";
import { Colors, Fonts, FontSizes } from "common/themes";

interface Props {
  campaignID: string;
  userID: string;
  displayName?: string;
  profileImage?: string;
  createdDate: string;
  imageURL?: string;
  isPostPage?: boolean;
}

export function CampaignCardHeader(props: Props) {
  const [isCopied, setIsCopied] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchor(null);
  };

  const { authToken, userData } = useContext(userContext);

  const onUnfollow = async () => {
    const unfollowParams = {
      data: {
        userID: props.userID,
        followerID: userData.username,
      },
      headers: {
        Authorization: "Bearer " + authToken,
      },
    };
    try {
      axios.delete(FOLLOWER_API, unfollowParams);
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  const onCopy = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(
      `${window.location.origin}${ROUTES.Post}/${props.campaignID}`
    );
  };

  return (
    <div className="campaign-card-header">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: 2,
          px: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
            {props.profileImage ? (
              <Box
                component="img"
                sx={{
                  height: "40px",
                  width: "40px",
                  objectFit: "cover",
                  borderRadius: 20,
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: Colors.White,
                  boxShadow: 3,
                }}
                src={props.profileImage}
                alt="profile"
              />
            ) : (
              <PersonIcon />
            )}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {props.displayName && (
              <Typography
                sx={{ fontFamily: Fonts.Title, fontWeight: 800, mr: 1 }}
              >
                {props.displayName}
              </Typography>
            )}
            <Typography
              sx={{ fontSize: FontSizes.Default }}
            >{`@${props.userID}`}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            aligneItems: "center",
          }}
        >
          <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontSize: FontSizes.Default }}>
              {props.createdDate}
            </Typography>
          </Box>
          {/* <div className="more-menu" onClick={handleClick}>
            {props.isPostPage && <MoreHorizIcon />}
          </div> */}
        </Box>
      </Box>
      <Menu open={open} anchorEl={menuAnchor} onClose={handleClose}>
        <MenuItem onClick={onCopy}>
          {isCopied ? "Copied" : "Copy Link"}
        </MenuItem>
        {/* <MenuItem onClick={onUnfollow}>{`Unfollow @${props.userID}`}</MenuItem> */}
      </Menu>
    </div>
  );
}
