export const Fonts = {
  Title: "Raleway",
  Default: "Source Sans Pro",
};

export const FontSizes = {
  BrandTitle: "1.5rem",
  Default: "15px",
};

export const FontWeights = {
  ExtraBold: 800,
};

export const Colors = {
  PrimaryPurple: "#5c36e7",
  FadedPurple: "rgba(92, 54, 231, .2)",
  White: "#ffffff",
  Black: "#000000",
  Grey: "#F2F2F2",
};

export const TitleStyles = {
  fontFamily: Fonts.Title,
  fontWeight: FontWeights.ExtraBold,
  color: Colors.PrimaryPurple,
  fontSize: FontSizes.BrandTitle,
};
