import { Box, Container } from "@mui/material";
import { CampaignData } from "features/campaign-context/component";
import { CampaignCard } from "./components/campaign-card";

interface Props {
  campaignData: CampaignData[];
  onCardClick?: (campaignID: string) => void;
}

export default function CampaignList(props: Props) {
  return (
    <Box
      className="campaign-list"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "600px",
        alignItems: "center",
      }}
    >
      {props.campaignData.length > 0 &&
        props.campaignData.map((item) => {
          return (
            <Container key={item.CreatedDate} sx={{ mt: 2, mb: 2 }}>
              <CampaignCard campaign={item} onCardClick={props.onCardClick} />
            </Container>
          );
        })}
    </Box>
  );
}
