import React from "react";
import { Contact } from "./components/contact";
import { ExamplesCard } from "./components/examples-card";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { HowItWorks } from "./components/how-it-works-card";
import { TitleCard } from "./components/title-card";
import { WhyCard } from "./components/why-card";
import LandingStyles from "./landing.module.css";

interface Props {}

export const LandingPage: React.FC<Props> = (props) => {
  return (
    <div className={LandingStyles.LandingPage}>
      <Header />
      <Footer />
      <TitleCard />
      <HowItWorks />
      <WhyCard />
      <ExamplesCard />
      <Contact />
    </div>
  );
};
