import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { AWSCONFIG } from "awsconfig";

const poolData = {
  UserPoolId: AWSCONFIG.cognito.userPoolId,
  ClientId: AWSCONFIG.cognito.userPoolClientId,
  Storage: new AmazonCognitoIdentity.CookieStorage({
    domain: "localhost",
    secure: true,
    sameSite: "strict",
  }),
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export function loginUser(userName: string, password: string, fn: Function) {
  const authenticationData = {
    Username: userName,
    Password: password,
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );
  const userData = {
    Username: userName,
    Pool: userPool,
    Storage: new AmazonCognitoIdentity.CookieStorage({
      domain: "localhost",
      secure: true,
      sameSite: "strict",
    }),
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (result) {
      const idData = result.getIdToken();
      const idToken = idData.getJwtToken();
      const userObject = {
        username: userName,
        firstName: idData.payload.name,
        lastName: idData.payload.family_name,
        email: idData.payload.email,
      };
      fn(null, idToken, userObject);
    },
    onFailure: function (err) {
      alert(err.message || JSON.stringify(err));
      fn(err, null);
    },
  });
}

export function addUserData(fn: Function) {
  const user = userPool.getCurrentUser();

  if (!user) {
    return;
  }

  user.getSession(function (err: any, session: any): string | undefined {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    const idData = session.getIdToken();
    const idToken = idData.getJwtToken();
    const userObject = {
      username: idData.payload["cognito:username"],
      firstName: idData.payload.name,
      lastName: idData.payload.family_name,
      email: idData.payload.email,
      authToken: idToken,
    };
    fn(userObject);
    return;

    // // NOTE: getSession must be called to authenticate user before calling getUserAttributes
    // user.getUserAttributes(function (err, attributes) {
    //   if (err) {
    //     // Handle error
    //   } else {
    //     console.log(attributes);
    //   }
    // });
  });
}

export function addAuth(fn: Function) {
  const user = userPool.getCurrentUser();

  if (!user) {
    return;
  }

  user.getSession(function (err: any, session: any): string | undefined {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    fn(session.getIdToken().jwtToken);
    return;

    // // NOTE: getSession must be called to authenticate user before calling getUserAttributes
    // user.getUserAttributes(function (err, attributes) {
    //   if (err) {
    //     // Handle error
    //   } else {
    //     console.log(attributes);
    //   }
    // });
  });
}

export function getUser() {
  const user = userPool.getCurrentUser();
  return user?.getUsername();
}

export function logoutUser() {
  const user = userPool.getCurrentUser();
  user?.signOut();
}
