import { CardActionArea, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import React from "react";
import { Colors, FontSizes } from "common/themes";

interface Props {
  campaignLink: string;
}

export function CardLink(props: Props) {
  return (
    <div className="campaign-card-link">
      <CardActionArea
        onClick={() => window.open(props.campaignLink, "_blank")}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          paddingY: 2,
          paddingX: 3,
          width: "100%",
          borderTopColor: Colors.Grey,
          borderTopWidth: 1,
          borderTopStyle: "solid",
          ":hover": {
            backgroundColor: Colors.Grey,
          },
        }}
      >
        <LinkIcon sx={{ mr: 1, color: Colors.PrimaryPurple }} />
        <Typography
          noWrap
          sx={{ fontSize: FontSizes.Default, color: Colors.PrimaryPurple }}
        >
          {props.campaignLink}
        </Typography>
      </CardActionArea>
    </div>
  );
}
