import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Fade,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExampleCardStyles from "./Examples.module.css";
import PurpleBackground from "../../../../common/assets/PurpleBackground.png";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import Example1 from "../../../../common/assets/PushieExample1.png";
import Example2 from "../../../../common/assets/PushieExample2.png";
import Example4 from "../../../../common/assets/PushieExample3.png";
import { Colors } from "common/themes";

export const ExamplesCard = () => {
  const theme = useTheme();

  const backgroundAdjust = useMediaQuery(theme.breakpoints.down("md"));

  const [currentCard, setCurrentCard] = useState(0);
  const [visible, setVisible] = useState(true);
  const [autoIncrement, setAutoIncrement] = useState(true);

  const incrementCount = useCallback(() => {
    setVisible(false);

    setTimeout(() => {
      setCurrentCard((currentCard) => (currentCard + 1) % 3);
      setVisible(true);
    }, 500);
  }, []);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (autoIncrement) {
      timerRef.current = setTimeout(incrementCount, 5000);
    } else {
      if (timerRef.current) clearTimeout(timerRef.current);
    }
  }, [autoIncrement, incrementCount, currentCard]);

  const handleIncrement = () => {
    setAutoIncrement(false);
    setVisible(false);

    setTimeout(() => {
      setCurrentCard((currentCard) =>
        currentCard + 1 > 2 ? 0 : currentCard + 1
      );
      setVisible(true);
    }, 500);
  };

  const handleDecrement = () => {
    setAutoIncrement(false);
    setVisible(false);

    setTimeout(() => {
      setCurrentCard((currentCard) =>
        currentCard - 1 < 0 ? 2 : currentCard - 1
      );
      setVisible(true);
    }, 500);
  };

  const handleSelect = (i: number) => {
    setAutoIncrement(false);
    setVisible(false);

    setTimeout(() => {
      setCurrentCard(i);
      setVisible(true);
    }, 500);
  };

  return (
    <div id="how-to-use">
      <Box
        style={{
          backgroundImage: "url(" + PurpleBackground + ")",
          backgroundSize: backgroundAdjust ? "auto 100%" : "100% 100%",
          backgroundPositionX: "right",
          marginBottom: "2rem",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingY: "5rem",
            textAlign: "center",
          }}
        >
          <div className={ExampleCardStyles.SectionTitle}>
            How you can use Pushie ✨
          </div>
          <Box sx={{ marginTop: "2rem" }}>
            <Fade in={visible} timeout={500}>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginY: "3rem",
                  [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "50%",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                >
                  {getImage(currentCard)}
                </Box>
                <Box
                  sx={{
                    height: "100%",
                    width: "50%",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                      marginY: "1.5rem",
                    },
                  }}
                >
                  <Box sx={{ textAlign: backgroundAdjust ? "center" : "left" }}>
                    <div className={ExampleCardStyles.Title}>
                      {getTitle(currentCard)}
                    </div>
                    <Box sx={{ marginTop: "1.5rem" }}>
                      <div className={ExampleCardStyles.Subtitle}>
                        {getSubTitle(currentCard)}
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Fade>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
            }}
          >
            <Button
              onClick={handleDecrement}
              disableRipple
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
                color: Colors.PrimaryPurple,
                height: "100%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
                height="100%"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </Button>
            <Box sx={{ height: "30px" }}>
              <Button
                disableRipple
                onClick={() => handleSelect(0)}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  color: Colors.PrimaryPurple,
                  height: "100%",
                  minWidth: "30px",
                }}
              >
                {currentCard === 0 ? (
                  <CircleIcon sx={{ height: "100%" }} />
                ) : (
                  <CircleOutlinedIcon sx={{ height: "100%" }} />
                )}
              </Button>
              <Button
                disableRipple
                onClick={() => handleSelect(1)}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  color: Colors.PrimaryPurple,
                  height: "100%",
                  minWidth: "30px",
                }}
              >
                {currentCard === 1 ? (
                  <CircleIcon sx={{ height: "100%" }} />
                ) : (
                  <CircleOutlinedIcon sx={{ height: "100%" }} />
                )}
              </Button>
              <Button
                disableRipple
                onClick={() => handleSelect(2)}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  color: Colors.PrimaryPurple,
                  height: "100%",
                  minWidth: "30px",
                }}
              >
                {currentCard === 2 ? (
                  <CircleIcon sx={{ height: "100%" }} />
                ) : (
                  <CircleOutlinedIcon sx={{ height: "100%" }} />
                )}
              </Button>
              {/* <Button
                disableRipple
                onClick={() => handleSelect(3)}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  color: Colors.PrimaryPurple,
                  height: "100%",
                  minWidth: "30px",
                }}
              >
                {currentCard === 3 ? (
                  <CircleIcon sx={{ height: "100%" }} />
                ) : (
                  <CircleOutlinedIcon sx={{ height: "100%" }} />
                )}
              </Button> */}
            </Box>
            <Button
              disableRipple
              onClick={handleIncrement}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
                color: Colors.PrimaryPurple,
                height: "100%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
                height="100%"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </Button>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

const getTitle = (i: number) => {
  switch (i) {
    case 0: {
      return "Notify followers of new content";
    }
    case 1: {
      return "Announce new merch drops or sales";
    }
    case 2: {
      return "Quickly communicate timely offers";
    }
    default: {
      return "Redistribute existing content";
    }
  }
};

const getSubTitle = (i: number) => {
  switch (i) {
    case 0: {
      return "Send out new videos or posts to your audience with a personalized message or additional context on what it's about.";
    }
    case 1: {
      return "Immediately update fans when you have new merch or items available. Make sure they don't miss out when there is an ongoing sale or limited stock left.";
    }

    case 2: {
      return "Instead of creating a whole new video just to communicate a message, get your information out quickly and make sure audience actually sees it.";
    }
    default: {
      return "Have an existing video that your audience will find relevant or interesting? Send it back out with some additional context.";
    }
  }
};

const getImage = (i: number) => {
  var image;
  switch (i) {
    case 0: {
      image = Example1;
      break;
    }
    case 1: {
      image = Example2;
      break;
    }
    case 2: {
      image = Example4;
      break;
    }
    default: {
      image = Example1;
    }
  }
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <img
        src={image}
        style={{ height: "200px", width: "auto" }}
        alt="example pushie"
      />
    </Box>
  );
};
