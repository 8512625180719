import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "pages/signup";
import Home from "pages/home/home";
import Login from "pages/login";
import { UserContextManager } from "features/user-context/component";
import { LandingPage } from "pages/landing";
import { Preview } from "pages/preview";
import { createTheme, ThemeProvider } from "@mui/material";
import "@fontsource/source-sans-pro";
import "@fontsource/raleway";

export const ROUTES = {
  Root: "",
  Home: "/",
  Signup: "/signup",
  Login: "/login",
  Profile: "/profile",
  Post: "/post",
};

const theme = createTheme({
  typography: {
    fontFamily: "Source Sans Pro",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-display: swap;
      `,
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <UserContextManager>
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/" element={<LandingPage />} />
              {/* <Route path="/signup" element={<Signup />} /> */}
              {/* <Route path="/login" element={<Login />} /> */}
              <Route
                path="/profile/:id"
                element={<Preview key={window.location.pathname} />}
              />
              <Route
                path="/post/:cid"
                element={<Preview key={window.location.pathname} />}
              />
              <Route path="/*" element={<LandingPage />} />
            </Routes>
          </BrowserRouter>
        </UserContextManager>
      </ThemeProvider>
    </div>
  );
}

export default App;
