import { Box, Container, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { userContext } from "features/user-context/component";
import { Colors, Fonts, FontSizes } from "common/themes";
import { Logo } from "common/logo";
import Linkify from "linkify-react";
import UserHeaderStyles from "./UserHeader.module.css";

interface Props {
  username: string;
  displayName: string;
  profileDescription?: string;
  profileImage?: string;
}

export function UserHeader(props: Props) {
  const { loading, userFollows } = useContext(userContext);
  // const [isFollowed, setIsFollowed] = useState<boolean | null>(null);

  useEffect(() => {
    // setIsFollowed(
    //   userFollows.some((user) => {
    //     return user.UserID === props.username;
    //   })
    // );
  }, [props.username, userFollows, loading]);

  // const onFollowClick = () => {
  //   if (isFollowed) {
  //     setIsFollowed(false);
  //     unfollowUser(props.username);
  //   } else {
  //     setIsFollowed(true);
  //     followUser(props.username);
  //   }
  // };

  return (
    <Container
      sx={{
        marginY: 2,
        paddingY: 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: "600px",
      }}
    >
      <Box
        sx={{
          mr: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.profileImage ? (
          <Box
            component="img"
            sx={{
              height: "100px",
              width: "100px",
              objectFit: "cover",
              borderRadius: 50,
              borderWidth: 3,
              borderStyle: "solid",
              borderColor: Colors.White,
              boxShadow: 5,
            }}
            src={props.profileImage}
            alt="profile"
          />
        ) : (
          <Box
            sx={{
              height: "100px",
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 50,
              borderWidth: 3,
              borderStyle: "solid",
              borderColor: Colors.White,
              boxShadow: 5,
              backgroundColor: Colors.FadedPurple,
            }}
          >
            <Logo height={50} width={50} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Typography
            sx={{
              fontFamily: Fonts.Title,
              fontWeight: 800,
              fontSize: "1.25rem",
            }}
          >
            {props.displayName}
          </Typography>
          <Typography
            sx={{ fontSize: FontSizes.Default }}
          >{`@${props.username}`}</Typography>
          {props.profileDescription && (
            <Typography
              sx={{
                fontSize: FontSizes.Default,
                whiteSpace: "pre-wrap",
              }}
            >
              <Linkify options={linkifyOptions}>
                {props.profileDescription}
              </Linkify>
            </Typography>
          )}
        </Box>
        {/* {!loading && (
            <Button onClick={onFollowClick}>
              {isFollowed ? "Unfollow" : "Follow"}
            </Button>
          )} */}
      </Box>
    </Container>
  );
}

const linkifyOptions = {
  target: "_blank",
  defaultProtocol: "https",
  className: UserHeaderStyles["userHeaderLink--url"],
};
