import { Box, Card, CardActionArea } from "@mui/material";
import { Colors } from "common/themes";
import { CampaignData } from "features/campaign-context/component";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { CampaignCardBody } from "./components/card-body";
import { CampaignCardHeader } from "./components/card-header";
import { CardLink } from "./components/card-link";
import { timeSince } from "./utils";

interface Props {
  campaign: CampaignData;
  isPostPage?: boolean;
  onCardClick?: (campaignID: string) => void;
}

export function CampaignCard(props: Props) {
  const navigate = useNavigate();

  const openCardLink = () => {
    if (props.onCardClick) {
      props.onCardClick(props.campaign.CampaignID);
    } else {
      const postPage = `/post/${props.campaign.CampaignID}`;
      navigate(postPage);
    }
  };

  const profileOnlyStyles = !props.isPostPage
    ? {
        ":hover": {
          boxShadow: 10,
          // cursor: "pointer",
        },
      }
    : {};

  const cardContents = () => {
    return (
      <>
        <CampaignCardHeader
          campaignID={props.campaign.CampaignID}
          userID={props.campaign.UserID}
          displayName={
            !!props.campaign.User?.DisplayName
              ? props.campaign.User.DisplayName
              : undefined
          }
          profileImage={
            !!props.campaign.User?.ProfileImage
              ? props.campaign.User.ProfileImage
              : undefined
          }
          createdDate={timeSince(props.campaign.CreatedDate)}
          isPostPage={props.isPostPage}
        />
        <CampaignCardBody
          campaignTitle={props.campaign.Title}
          campaignBody={props.campaign.Body}
          imageURL={props.campaign.Image}
        />
      </>
    );
  };

  return (
    <div className={"campaign-card"}>
      <Card
        sx={{
          boxShadow: 5,
          borderRadius: "16px",
          ...profileOnlyStyles,
        }}
      >
        {!props.isPostPage ? (
          <CardActionArea
            onClick={!props.isPostPage ? openCardLink : undefined}
            sx={{
              ".MuiCardActionArea-focusHighlight": {
                background: "transparent",
              },
            }}
          >
            {cardContents()}
          </CardActionArea>
        ) : (
          cardContents()
        )}
        {props.campaign.Image && (
          <Box
            sx={{
              "box-sizing": "border-box",
              display: "flex",
              justifyContent: "center",
              marginBottom: 2,
              paddingX: 2,
            }}
          >
            <Box
              onClick={() => {
                if (props.campaign.Link) {
                  window.open(props.campaign.Link, "_blank");
                }
              }}
              component="img"
              sx={{
                maxWidth: "100%",
                borderRadius: 5,
                cursor: props.campaign.Link ? "pointer" : undefined,
              }}
              src={props.campaign.Image}
              alt="thumbnail"
            />
          </Box>
        )}
        {props.campaign.Link && <CardLink campaignLink={props.campaign.Link} />}
      </Card>
    </div>
  );
}
