import React from "react";
import { Box, Container } from "@mui/material";
import HeaderStyles from "./Header.module.css";
import { Logo } from "common/logo";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Props {}

const ITEM_HEIGHT = 48;

export const Header: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    <Container
      sx={{
        paddingY: "2.5rem",
        position: "absolute",
        left: "50%",
        WebkitTransform: "translateX(-50%)",
        transform: "translateX(-50%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Logo height={50} width={50} />
          <div className={HeaderStyles.Logo}>pushie</div>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {isMobile ? <MobileMenu /> : <DesktopMenu />}
        </Box>
      </Box>
    </Container>
  );
};

const DesktopMenu = () => {
  return (
    <>
      <Box sx={{ marginRight: "1rem" }}>
        <a href="#how-it-works" className={HeaderStyles.Label}>
          How it Works
        </a>
      </Box>
      <a href="#why-pushie" className={HeaderStyles.Label}>
        Why use Pushie
      </a>
    </>
  );
};

const MobileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <a href="#how-it-works" className={HeaderStyles.Label}>
            How it Works
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href="#why-pushie" className={HeaderStyles.Label}>
            Why use Pushie
          </a>
        </MenuItem>
      </Menu>
    </>
  );
};
