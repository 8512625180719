import React from "react";
import { Box, Container, useTheme } from "@mui/material";
import TitleCardStyles from "./TitleCard.module.css";
import PushieWalkthrough from "../../../../common/assets/pushiewalkthrough.gif";

interface Props {}

export const TitleCard: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
        paddingY: "150px", // height of header and footer
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "50%",
          [theme.breakpoints.down("md")]: {
            width: "100%",
            marginBottom: "1.5rem",
          },
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <div className={TitleCardStyles.Title}>
            Connect directly with your audience
          </div>
          <Box sx={{ marginTop: "1.5rem" }}>
            <div className={TitleCardStyles.Subtitle}>
              Say goodbye to relying on social media algorithms and ensure your
              audience always gets your message directly. Pushie puts the
              control back in your hands, enabling you to create and send custom
              push notifications that your community will actually see. Define
              the push, decide where it leads, and watch your content reach its
              full potential.
            </div>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "50%",
          marginLeft: "1.5rem",
          [theme.breakpoints.down("md")]: {
            width: "100%",
            marginLeft: 0,
            marginTop: "1rem",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={PushieWalkthrough}
          style={{ height: "550px", width: "auto" }}
          alt="pushie walkthrough gif"
        />
      </Box>
    </Container>
  );
};
